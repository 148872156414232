<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <organization-selector
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />
        <base-input>
          <el-select
            :label="$t('DPA_PPA.STATUS')"
            :placeholder="$t('DPA_PPA.STATUS')"
            v-model="selectedStatut"
            @change="
              (status) => {
                selectedStatut = status;
              }
            "
          >
            <el-option
              v-for="(value, key) in FILE_STATUS_LIST"
              :key="key"
              :value="value"
              :label="value ? $t(`DPA_PPA.${value}`) : ''"
            />
          </el-select>
        </base-input>
        <base-button
          class="btn base-button refresh btn-icon btn-fab btn-info"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.666016 3.46648V7.46648M0.666016 7.46648H4.66602M0.666016 7.46648L3.75935 4.55981C4.47585 3.84296 5.36226 3.31929 6.33589 3.03766C7.30951 2.75604 8.33861 2.72565 9.32716 2.94932C10.3157 3.17299 11.2315 3.64344 11.989 4.31676C12.7466 4.99008 13.3212 5.84434 13.6593 6.79981M15.3327 14.1331V10.1331M15.3327 10.1331H11.3327M15.3327 10.1331L12.2393 13.0398C11.5229 13.7567 10.6364 14.2803 9.66281 14.562C8.68919 14.8436 7.66009 14.874 6.67154 14.6503C5.68299 14.4266 4.76722 13.9562 4.00966 13.2829C3.2521 12.6095 2.67746 11.7553 2.33935 10.7998"
                stroke="white"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </base-button>
        <base-input
          v-model="query"
          type="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="dpaFiles"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>

            <el-table-column
              :label="$t('DPA_PPA.CODE')"
              prop="code"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                {{ row.code }}
              </template>
            </el-table-column>

            <el-table-column :label="null" prop="status" min-width="70">
              <template v-slot:header>
                <ArrowsUpDown />
              </template>
              <template v-slot="{ row }">
                <in-out-badge :type="row.transaction_type" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('DPA_PPA.TOTAL_TRANSACTIONS')"
              prop="total_transactions"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                {{ row.total_transactions }}
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('DPA_PPA.TOTAL_AMOUNT')"
              prop="total_amount"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                <amount-badge :amount="row.total_amount" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('DPA_PPA.STATUS')"
              prop="status"
              min-width="250"
            >
              <template v-slot="{ row }">
                <status-badge :status="row.status" />
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              min-width="200"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>

            <!-- <el-table-column
              :label="$t('DPA_PPA.FILE')"
              prop="file_name"
              min-width="300"
            >
              <template v-slot="{ row }">
                <object-details
                  v-if="row.file_name"
                  :data="showFileData(row)"
                  :with-trad="true"
                  trad-path="DPA_PPA"
                  :label-column-width="50"
                />
                <span v-else>-</span>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('DPA_PPA.PROCESSING')"
              prop="processed_file_name"
              min-width="300"
            >
              <template v-slot="{ row }">
                <object-details
                  v-if="row.processed_file_name"
                  :data="showResponseFileData(row)"
                  :with-trad="true"
                  trad-path="DPA_PPA"
                  :label-column-width="50"
                />
                <span v-else>-</span>
              </template>
            </el-table-column> -->

            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="200"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at) }}
              </template>
            </el-table-column>

            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_DPATRANSACTIONS)"
                >
                  <a
                    type="text"
                    @click="viewDpaFile(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <img src="/img/expand-icon.svg" alt="icon" />
                  </a>
                </el-tooltip>
                <!-- <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_DPATRANSACTIONS)"
                >
                  <a
                    type="text"
                    @click="editDpaFile(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip> -->

                <el-tooltip
                  :content="
                    row.status === STATUS_GENERATED
                      ? $t('COMMON.DELETE')
                      : $t('DPA_PPA.CANNOT_DELETE_TRANSACTION')
                  "
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_DPATRANSACTIONS)"
                >
                  <a
                    type="text"
                    @click="
                      row.status === STATUS_GENERATED && deleteDpaFile(row.id)
                    "
                    :class="[
                      'table-action',
                      'table-action-delete',
                      { 'disabled-link': row.status !== STATUS_GENERATED },
                    ]"
                    :style="{
                      cursor:
                        row.status === STATUS_GENERATED
                          ? 'pointer'
                          : 'not-allowed',
                    }"
                  >
                    <img src="/img/delete-icon.svg" alt="icon" />
                  </a>
                </el-tooltip>

                <!-- <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_DPATRANSACTIONS)"
                >
                  <a
                    type="text"
                    @click="deleteDpaFile(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip> -->
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
//import ObjectDetails from "@/components/ElixioWallet/components/atoms/ObjectDetails.vue";
import AmountBadge from "@/components/ElixioWallet/components/atoms/AmountBadge.vue";
import ArrowsUpDown from "@/components/ElixioWallet/Icons/ArrowsUpDown.vue";
import InOutBadge from "@/components/ElixioWallet/components/atoms/InOutBadge.vue";
import StatusBadge from "@/components/ElixioWallet/components/atoms/StatusBadge.vue";
import { FILE_STATUS_LIST, STATUS_GENERATED } from "@/constants/dpaPpa";

export default {
  name: "dpaFile-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    //ObjectDetails,
    AmountBadge,
    InOutBadge,
    ArrowsUpDown,
    StatusBadge,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      selectedStatut: null,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      dpaFiles: [],
      loading: true,
      selectedOrganization: null,
      FILE_STATUS_LIST: FILE_STATUS_LIST,
      STATUS_GENERATED: STATUS_GENERATED,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatut: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      if (
        this.selectedOrganization === null &&
        this.query === null &&
        this.selectedStatut == null
      ) {
        this.getList();
      }
      this.selectedStatut = null;
      this.selectedOrganization = null;
      this.query = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    showFileData(row) {
      return {
        FILE_NAME: row.file_name,
        GENERATED_AT: this.$formatDate(row.created_at),
        SENT_AT: row.sent_at == null ? "-" : this.$formatDate(row.sent_at),
      };
    },

    showResponseFileData(row) {
      let responseData = {
        FILE_NAME: row.processed_file_name,
        PROCESSED_AT:
          row.processed_at == null ? "-" : this.$formatDate(row.processed_at),
      };
      if (row.error_message) {
        responseData.ERROR_MESSAGE = row.error_message;
      }
      return responseData;
    },

    showDebitCreditData(debitValue, creditValue) {
      return {
        DEBIT: debitValue,
        CREDIT: creditValue,
      };
    },
    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedStatut) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatut,
            },
          };
        }
        await this.$store.dispatch("dpaFiles/list", params);
        this.dpaFiles = this.$store.getters["dpaFiles/list"];
        this.total = this.$store.getters["dpaFiles/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editDpaFile(customer) {
      this.$emit("onEditDpaFile", customer);
      /* this.$router.push({
        name: "Edit Customer",
        params: { id: row.id },
      }); */
    },

    async deleteDpaFile(id) {
      const confirmation = await swal.fire({
        title: this.$t("DPA_PPA.DELETE_THIS_FILE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("dpaFiles/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("DPA_PPA.FILE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewDpaFile(dpaFile) {
      this.$emit("onViewDpaFile", dpaFile);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
